import React, { useState, useContext, useLayoutEffect, useRef, useEffect } from 'react';
import _ from 'lodash';
import { navigate } from 'gatsby';
import { Location } from '@reach/router';
import {
  Drawer,
  Box,
  Collapse,
  List,
  AppBar,
  CssBaseline,
  IconButton,
  MenuItem,
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  Paper,
  Typography,
  Avatar,
} from '@mui/material';
import Imgix from 'react-imgix';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';

import { UserContext } from '../../context/UserContext';
import { drawerWidth, RoutesToNavigate, Types } from '../../constants';
import defaultLogo from '../../images/default-logo.jpg';
import { AuthenticationAPI, GatewayManagementAPI } from '../../services';
import HeaderButtons from './HeaderButtons';
import * as Utils from '../../utils';
import { getFormattedMenuItems } from './utils/utility-funcs';
import { navigateToRoute } from '../../utils/navigateLandingRoute';
import { FeedbackBtn } from './Feedback';
import {
  useDrawerStyles,
  useSidebarStyles,
  StyledListItem,
  StyledListSubItem,
  LogOutItem,
} from './styles/sidebar-styles';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { menuItemsSliceActions } from '../../redux/gtw-menu-items-slice';
import { RootState } from '../../redux';
import { menuItemsIconsEnum } from '../Types';
import { menuItemsIcons } from './icons';

export const getPathReady = (path: string) => {
  return path.endsWith('/') ? path.slice(0, -1) : path;
};

interface SidebarCustomProps {
  children: any;
}

const SidebarCustom = ({ children }: SidebarCustomProps) => {
  const [expandMenu, setExpandMenu] = useState(true);
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 900);
  const isExpandMenuPermanent = isMobileScreen ? true : expandMenu;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [collapseIn, setColapseIn] = useState('');
  const [open, setOpen] = useState(false);
  const [menuItemId, setMenuItemId] = useState('');

  const userContext = useContext(UserContext);
  const dispatchReduxAction = useAppDispatch();
  const anchorRef = useRef(null);

  const menuItemsArray = useAppSelector((state: RootState) => state.gtwMenuItemsSliceData.menuItemsArray);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    checkIsMenuPreferenceSet();
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useLayoutEffect(() => {
    getMenuItems();
  }, [userContext.shadowedRole.isShadowed, userContext.shadowedRole.shadowedRole.id]);

  useEffect(() => {
    if (userContext.role.success) {
      getAllQuicksights();
      getDefaultMenuItems();
    }
  }, [userContext.role.success]);

  const getAllQuicksights = async () => {
    const allQuicksightsRes = await GatewayManagementAPI.getAllQuicksights();
    if (allQuicksightsRes.success && allQuicksightsRes.allQuicksights?.length) {
      dispatchReduxAction(menuItemsSliceActions.setAllQuicksights(allQuicksightsRes.allQuicksights ?? []));
    }
  };

  const getDefaultMenuItems = async () => {
    const defaultMenuItemsRes = await GatewayManagementAPI.getDefaultMenuItems();
    if (defaultMenuItemsRes.success && defaultMenuItemsRes.defaultMenuItems?.length) {
      dispatchReduxAction(menuItemsSliceActions.setDefaultMenuItems(defaultMenuItemsRes.defaultMenuItems ?? []));
    }
  };

  const getMenuItems = async () => {
    const menuItemsRes = await GatewayManagementAPI.getMenuItems();
    if (menuItemsRes.success && menuItemsRes.menuItems) {
      const { formattedItems, roleQuicksights } = getFormattedMenuItems(menuItemsRes.menuItems, '_classname');

      dispatchReduxAction(menuItemsSliceActions.setMenuItems(formattedItems));
      dispatchReduxAction(menuItemsSliceActions.setRoleQuicksights(roleQuicksights ?? []));

      const quicksiteId = localStorage.getItem('quicksiteId');
      if (quicksiteId && quicksiteId.length > 0) {
        for (const item of formattedItems) {
          if (item.subItems) {
            const isSubItem = item.subItems.find((subItem) => subItem.link.iframeId === quicksiteId);
            if (isSubItem) {
              setMenuItemId(quicksiteId);
              break;
            }
          }
          if (!item.subItems && item.link.iframeId && item.link.iframeId === quicksiteId) {
            setMenuItemId(item.id);
            break;
          }
        }
      } else {
        formattedItems.map((menuItem) => window.location.href.includes(menuItem.link.to) && setMenuItemId(menuItem.id));
      }
    } else {
      const message = 'An error occurred while retrieving menu items data.';
      userContext.setToastifySettings({
        type: Types.MessageTypes.Error,
        msg: message,
      });
      Utils.navigateToLogin();
    }
  };

  const checkIsMenuPreferenceSet = () => {
    try {
      const isMenuExpandedSet = localStorage.getItem('isMenuExpanded');
      if (isMenuExpandedSet) {
        const isMenuExpanded = isMenuExpandedSet === 'true' ? true : false;
        setExpandMenu(isMenuExpanded);
        return;
      }
      localStorage.setItem('isMenuExpanded', 'true');
    } catch (e) {
      console.error(e);
    }
  };

  const updateSize = () => {
    if (window.innerWidth > 900) {
      setMobileOpen(false);
      setIsMobileScreen(false);
    }
    if (window.innerWidth < 900) {
      setIsMobileScreen(true);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (item: Types.MenuItem) => {
    localStorage.removeItem('quicksiteId');
    const _currentLink = item?.link?.to;

    if (_currentLink?.includes('lnwportal_addepar') || _currentLink?.includes('mirador_addepar')) {
      window.open(_currentLink, '_blank');
    } else if (item.subItems) {
      setColapseIn(collapseIn === item.title ? '' : item.title);
    } else {
      setColapseIn('');
      if (_currentLink === RoutesToNavigate.ALTS_DASHBOARD) {
        localStorage.setItem('quicksiteId', item.link.iframeId || '');
      }
      setMenuItemId(item.id);
      navigate(_currentLink);
      setMobileOpen(false);
    }
  };

  const handleSubClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: Types.MenuItemSubitems) => {
    e.stopPropagation();
    if (item.link.to === RoutesToNavigate.ALTS_DASHBOARD) {
      localStorage.setItem('quicksiteId', item.link.iframeId || '');
      setMenuItemId(item.link.iframeId as string);
      navigate(RoutesToNavigate.ALTS_DASHBOARD);
    } else {
      setMenuItemId(item.link.iframeId as string);
      navigate(item.link.to);
    }

    setMobileOpen(false);
  };

  const handleDynamicRoutingClick = (e: any, item: Types.MenuItemSubitems) => {
    e.stopPropagation();

    if (item.link.to === RoutesToNavigate.ALTS_DASHBOARD) {
      localStorage.setItem('quicksiteId', item.link.iframeId || '');
      setMenuItemId(item.link.iframeId as string);
      navigate(RoutesToNavigate.ALTS_DASHBOARD);
    } else {
      setMenuItemId(item.link.iframeId as string);
      navigate(`${item.link.to}`);
    }

    setMobileOpen(false);
  };

  const onLogOut = async () => {
    await AuthenticationAPI.logout();
  };

  const onSettings = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    handleClose(e);
    navigate('/settings');
  };

  const onClickLogo = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();

    navigateToRoute(userContext.role);
  };

  const sidebarStyles = useSidebarStyles(expandMenu, drawerWidth);

  const drawer = (isShowSettings: boolean) => (
    <Location>
      {({ location }) => {
        const path = getPathReady(location.pathname);
        const _menuItems = _.cloneDeep(menuItemsArray || []);
        if (isShowSettings) {
          _menuItems.push({
            title: 'Settings',
            link: { to: '/settings' },
            icon: <SettingsIcon style={{ color: 'white' }} />,
            selected: false,
            id: '65ddc88f395cb8759d147621',
          });
        }

        for (const [index, item] of Object.entries(_menuItems)) {
          const isSelected = Utils.isCurrentMenuItemSelected(item, path);

          _menuItems[Number(index)].selected = isSelected;

          if (item.title === 'View All') {
            setColapseIn(item.title);
          }
        }

        return (
          <List sx={sidebarStyles.menuItemsList}>
            {_menuItems.map((item) => (
              <StyledListItem
                key={item.title + item.id}
                onClick={() => handleClick(item)}
                style={{ paddingLeft: isExpandMenuPermanent ? '' : 0 }}
                className={
                  item.subItems
                    ? window.location.href.includes(item.link.to) &&
                      (item.subItems.map((el) => el.link.iframeId).filter((el) => el).length > 0
                        ? item.subItems.map((el) => el.link.iframeId).includes(menuItemId)
                        : true)
                      ? 'active'
                      : ''
                    : window.location.href.includes(item.link.to) && item.id === menuItemId
                      ? 'active'
                      : ''
                }
                id={item.id || ''}
              >
                <div className="listMainItem">
                  <div className="listIcon">
                    {item.icon}
                    {!!item.subItems && (
                      <ExpandLess
                        style={{
                          position: 'absolute',
                          left: isExpandMenuPermanent ? undefined : -5,
                          right: isExpandMenuPermanent ? 12 : 0,
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          ...(collapseIn !== item.title && { transform: 'rotate(180deg)' }),
                          ...(!isExpandMenuPermanent &&
                            (collapseIn !== item.title ? { bottom: '-6px' } : { top: '-6px' })),
                        }}
                      />
                    )}
                  </div>
                  <div
                    className={`${
                      item.subItems
                        ? window.location.href.includes(item.link.to) &&
                          item.subItems.map((el) => el.link.iframeId).includes(menuItemId)
                          ? 'selectedMenuItem'
                          : 'menuItem'
                        : window.location.href.includes(item.link.to) && item.id === menuItemId
                          ? 'selectedMenuItem'
                          : 'menuItem'
                    }`}
                  >
                    {isExpandMenuPermanent && <p className="menuItemTitle">{item.title}</p>}
                  </div>
                </div>
                {!!item.subItems &&
                  item.subItems.map((subItem: Types.MenuItemSubitems) => {
                    return (
                      <Collapse
                        in={item.title === collapseIn}
                        timeout="auto"
                        unmountOnExit
                        key={String(subItem.title) + String(subItem.link)}
                        sx={{ width: '100%' }}
                      >
                        <List component="div" disablePadding>
                          <StyledListSubItem
                            className="nested"
                            onClick={(e) =>
                              subItem.isDynamic ? handleDynamicRoutingClick(e, subItem) : handleSubClick(e, subItem)
                            }
                          >
                            <div
                              className={
                                item.selected && subItem.title === 'View All' ? 'selectedMenuItem' : 'menuItem'
                              }
                            >
                              {isExpandMenuPermanent ? subItem.title : Utils.getFirstCharOfEachWord(subItem.title)}
                            </div>
                          </StyledListSubItem>
                        </List>
                      </Collapse>
                    );
                  })}
              </StyledListItem>
            ))}
          </List>
        );
      }}
    </Location>
  );

  const LogOut = (smDown: boolean) => {
    return (
      <div className={smDown ? 'smBottomPush' : 'bottomPush'}>
        <LogOutItem style={{ paddingLeft: isExpandMenuPermanent ? '' : 0 }} onClick={onLogOut}>
          <div className="listMainItem">
            <div className="listIcon">
              <ExitToAppIcon />
            </div>
            {isExpandMenuPermanent && <div className="menuItem">LOG OUT</div>}
          </div>
        </LogOutItem>
      </div>
    );
  };

  const handleClose = (event: any) => {
    // @ts-ignore: Unreachable code error
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box sx={sidebarStyles.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={(theme) => ({
          height: '4.75rem',
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0 20px',
          boxShadow: '0 3px 6px 0 rgba(0,0,0,0.16)',
          [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
          },
        })}
      >
        <div onClick={onClickLogo} className="logo">
          {!!userContext.role.logo ? (
            <Imgix src={userContext.role.logo} height={40} />
          ) : (
            <img src={defaultLogo} className="defaultLogo" />
          )}
        </div>
        <div className="headerRight">
          <FeedbackBtn />
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <HeaderButtons />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Button
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={() => setOpen((prevOpen) => !prevOpen)}
              disableElevation
              sx={{ textTransform: 'none' }}
            >
              <Avatar
                src={userContext.user.photo?.imgix_url || ''}
                sx={(theme) => ({
                  color: 'white',
                  backgroundColor: theme.palette.primary.main,
                })}
              >
                {Utils.getAbbreviationOfName(userContext.user.name || '')}
              </Avatar>
              <Typography
                sx={{
                  color: '#0A354C',
                  fontSize: '16px',
                  fontFamily: 'Proxima Nova, sans-serif',
                  marginLeft: '10px',
                }}
              >
                {userContext.user.name || ''}
              </Typography>
              <ExpandMore
                sx={
                  open
                    ? {
                        transform: 'rotate(180deg)',
                        transition: 'transform 0.1s linear',
                        color: '#0A354C',
                      }
                    : {
                        transform: 'rotate(0deg)',
                        transition: 'transform 0.1s linear',
                        color: '#0A354C',
                      }
                }
              />
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList sx={{ padding: 0 }} autoFocusItem={open} id="menu-list-grow">
                        <MenuItem
                          onClick={onSettings}
                          sx={{
                            height: '2.5rem',
                            minWidth: '175px',
                          }}
                        >
                          <div className="headerDropdownIconContainer">
                            <SettingsIcon
                              sx={{
                                marginRight: '10px',
                                fontSize: '25px',
                              }}
                            />
                          </div>
                          Settings
                        </MenuItem>
                        <MenuItem
                          onClick={onLogOut}
                          sx={{
                            height: '2.5rem',
                            minWidth: '175px',
                          }}
                        >
                          <div className="headerDropdownIconContainer">
                            <ExitToAppIcon
                              sx={{
                                marginRight: '10px',
                                fontSize: '25px',
                              }}
                            />
                          </div>
                          Log out
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={(theme) => ({
              marginRight: theme.spacing(2),
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
            })}
          >
            <MenuIcon sx={(theme) => ({ color: theme.palette.primary.main })} />
          </IconButton>
        </div>
      </AppBar>
      <Box
        sx={{
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <nav className="drawer">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={useDrawerStyles(false, expandMenu, drawerWidth)}
            ModalProps={{
              keepMounted: true,
            }}
            hideBackdrop={false}
          >
            <IconButton
              onClick={handleDrawerToggle}
              sx={{
                marginRight: 'auto',
                marginLeft: 0,
                marginTop: '15px',
              }}
            >
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
            {drawer(true)}
            <HeaderButtons />
            {LogOut(true)}
          </Drawer>
        </nav>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <nav className="drawer">
          <Drawer variant="persistent" open={true} sx={useDrawerStyles(true, expandMenu, drawerWidth)}>
            {drawer(false)}
            {LogOut(false)}
            <div
              onClick={() => {
                const isMenuExpandedUpdated = !isExpandMenuPermanent;
                localStorage.setItem('isMenuExpanded', String(isMenuExpandedUpdated));
                setExpandMenu(isMenuExpandedUpdated);
              }}
              className="drawerArrowExpanded"
            >
              <ExpandLess
                style={{
                  transform: isExpandMenuPermanent ? 'rotate(-90deg)' : 'rotate(90deg)',
                  height: '20px',
                  marginLeft: isExpandMenuPermanent ? '-2px' : 0,
                }}
              />
            </div>
          </Drawer>
        </nav>
      </Box>
      <div className="content" id="mirador-main-content">
        {children}
      </div>
    </Box>
  );
};

export default SidebarCustom;
